import React from 'react';
import BaseLandingPage from 'style-book/landing-page/BaseLandingPage';
import SeoComponent from 'style-book/seo/SeoComponent';
import { getStoredLandingPage } from 'utils/lp-utils';

import './index.css';

function IndexPage() {
  const lpService = getStoredLandingPage();
  return <BaseLandingPage lpService={lpService} />;
}

export default IndexPage;

export function Head() {
  const lpService = getStoredLandingPage();
  return (
    <SeoComponent
      title="Simplify Your Small Business Marketing Success with goalsKeeper.io - Effortless Data Insights for Digital Marketers"
      description="Simplify marketing success for your small business with GoalsKeeper.io. Our platform provides digital marketers with intuitive data insights, eliminating the complexity of data analysis. Effortlessly connect your marketing tools, create customized dashboards, and make informed decisions to optimize your strategies. Drive results and achieve your marketing goals with ease. Sign up for a free trial today!"
      canonicalUrl="goalskeeper.io/"
    />
  );
}
